import "./App.css";

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const launchUrlEncoded = urlParams.get("launch");

  if (launchUrlEncoded) {
    window.location.href = decodeURI(launchUrlEncoded);
  }

  return <div className="App"></div>;
}

export default App;
